<template>
  <div class="container">
    <div class="top">
      <span>下午茶的时间到了，休息一下吧！15001002938</span>
    </div>
    <div class="cot">
      <div class="cot1">
        <div class="cot1_company">
          <div class="company_lf">
            <div class="company_lf_1">
              <img :src="companyInfo.log_img"  alt="" />
              <div class="open">
                <div class="wz">
                  <span>企业号</span>
                  <p>{{companyInfo.open}}</p>
                </div>
                <div class="wz">
                  <span>昵称</span>
                  <p>{{ companyInfo.contact }}</p>
                </div>
              </div>
            </div>
            <div class="company_lf_2">
              <span>企业名称</span>
              <div class="company_name">
                {{companyInfo.company_name}}
                <p v-if="companyInfo.is_real==1">已认证</p>
                <p v-else>未认证</p>
              </div>
            </div>
            <div class="cot1_lf_3">
              <span>企业Logo</span>
              <img :src="companyInfo.log_img" alt="" />
            </div>
          </div>
          <div class="company_rt">
            <div class="rs">
              <span>人数</span>
              <p>{{companyInfo.number}}人</p>
            </div>
            <div class="rs">
              <span>身份</span>
              <p>{{companyInfo.identity}}</p>
            </div>
          </div>
        </div>
        <div class="cot1_rt">
            <div class="menu-cot">
              <div class="menu">
                <div class="logo" @click="goReport">
                  <img src="~@/assets/index-image/indexlogo.png" alt="" />
                  <span>汇报</span>
                </div>
                <div class="logo" @click="goApproval">
                  <img src="~@/assets/index-image/indexlogo.png" alt="" />
                  <span>审批</span>
                </div>
                <div class="logo"  @click="goNotice">
                  <img src="~@/assets/index-image/indexlogo.png" alt="" />
                  <span>公告</span>
                </div>
                <div class="logo"  @click="goClock">
                  <img src="~@/assets/index-image/indexlogo.png" alt="" />
                  <span>打卡</span>
                </div>
               
              </div>
            </div>

          
         
        </div>
      </div>

   
      <div class="cot2">
        <div class="notice_head">
            <span>通知:</span>
            <p>最新通知</p>
          </div>
          <div class="notice_list">
            <ul>
              <li  v-for="(item, index) in noticePageProps.records" :key="index">
               {{item.abstract}}
              </li>
              
            </ul>
            <div class="notice_more" @click="goNotice">
              <p></p>
              查看更多
              <p></p>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import { companyMydata,noticePlay} from "@/api/home/index";

export default {
  name: "Login",

  data() {
    return {
     
      companyInfo: {
        company_name: "",
        log_img: "",
        contact: "",
        is_real:"",//1实名，2未实名
        open:"",//企业号
        number:"",//人数
        identity	:"",//身份
      },
      noticeList:[],
      
      noticePageProps:{
        records:[],
          total: 0,
          pageSize: 5,
          pageNum: 1
      },
      noticeSearchFormData:{
          name:"",
          page:0,
          limit:0
        }
    };
  },
  mounted(){
    this.initInfo();
    this.getNotice();
  },
  methods: {

    initInfo(){
      var that = this;
      companyMydata({}).then((response) => {
        console.log(response);
        if(response.code==200){
          that.companyInfo=response.data
        }else{
          
          that.$message.error(response.msg?response.msg:"请求错误");
        }
      });
    },

    getNotice(){
      var that = this;
      noticePlay({}).then((response) => {
        console.log(response);
        if(response.code==200){
          that.noticePageProps.records = response.data.data;
           that.noticePageProps.total = response.data.total;
        }else{
          
          that.$message.error(response.msg?response.msg:"请求错误");
        }
      });
    },

      // 当前页变更
      handleCurrentChange(num) {
        this.noticePageProps.pageNum = num
        this.getNotice()
      },


      // 每页显示页面数变更
      handleSizeChange(size) {
        this.noticePageProps.pageSize = size
        this.getNotice()
      },

      goNotice(){
         this.$router.push({ name: "application-notice-list" });
      },
       goReport(){
         this.$router.push({ name: "application-report-list" });
      },
       goApproval(){
         this.$router.push({ name: "application-approval-list" });
      }, 
      goClock(){
         this.$router.push({ name: "application-clock-report-day" });
      }
  },
};
</script>

<style lang='scss' scoped>
.top {
  padding: 7px 26px;
  background-color: #fff;
  span {
    font-size: 20px;
  }
}
.cot {
  margin-top: 30px;
  background-color: #fff;
}
.cot1 {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #ddd;
  padding: 26px 26px 10px;
  .cot1_company {
    display: flex;
    .company_lf {
      .company_lf_1 {
        width: 300px;
        display: flex;
        img {
          width: 60px;
          height: 60px;
          border-radius: 4px;
        }
        .open {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 40px;
          .wz {
            display: flex;
            span {
              width: 80px;
            }
          }
        }
      }
      .company_lf_2 {
        display: flex;
        margin-top: 20px;
        span {
          width: 100px;
        }
        .company_name {
          display: flex;
          p {
            width: 44px;
            height: 20px;
            line-height: 20px;
            text-align: center;
            border: 1px solid #4c71db;
            border-radius: 4px;
            font-size: 12px;
            color: #4c71db;
            margin-left: 15px;
          }
        }
      }
      .cot1_lf_3 {
        margin-top: 20px;
        display: flex;
        span {
          width: 100px;
        }
        img {
          width: 200px;
          height: auto;
        }
      }
    }

    .company_rt {
      margin-left: 50px;
      .rs {
        display: flex;
        margin-top: 20px;
        span {
          width: 60px;
        }
      }
      .rs:first-child {
        margin: 0;
      }
    }
  }

  .cot1_rt {
    width: 410px;

    .menu-cot {
      width: 460px;
      .menu {
        display: flex;
        flex-wrap: wrap;
        padding: 43px 43px 6px;
        .logo {
          display: flex;
          flex-direction: column;
          margin-right: 70px;
          margin-bottom: 20px;
          align-items: center;
          img {
            width: 40px;
            height: 50px;
          }
          span {
            margin-top: 10px;
          }
        }
        .logo:nth-child(4) {
          margin-right: 0px;
        }
      }
    }

  }
}


.cot2 {
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  padding: 20px;
  .notice_head {
      display: flex;
      justify-content: space-between;
      p {
        font-size: 12px;
        color: #4c71db;
      }
    }

    .notice_list {
      margin-top: 15px;
      ul {
        padding: 0 15px;
       
        li {
          font-size: 14px;
          overflow: hidden;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 20px;
          margin-top: 15px;
        }
      }
      .notice_more {
        display: flex;
        margin: 10px auto;
        align-items: center;
        justify-content: center;
        color: #4c71db;
        p {
          width: 120px;
          border: 0.5px solid #f8f8f8;
          margin: 0 10px;
        }
      }
    }

}
</style>
