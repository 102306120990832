import request from '@/api/request'

//PC企业端-首页信息
export function companyMydata(data) {
    return request({
        url: '/company/company/user/Mydata',
        method: 'GET',
        params:data
    })
}


export function noticePlay(data) {
    return request({
        url: '/company/company/notice/play',
        method: 'GET',
        params:data
    })
}






